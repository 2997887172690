import DataTable from '../DataTable';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import ServicesEmailMenu from '../ServicesEmailMenu';
import moment from 'moment';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {connect, useSelector} from 'react-redux';
import {faStickyNote} from '@fortawesome/pro-duotone-svg-icons';
import {
    fetchVehiclesBlocked,
} from '../../api/apiVehicles';
import {isEmpty} from 'ramda';
import {red} from '@material-ui/core/colors';
import {setBreadcrumbs} from '../../redux/user';
import {subStatusToTitle} from '../../core/utils';
import {vehiclesBlockedModel} from '../../redux/vehicles';

const DataTableColumns = [
    {
        id: 'referenceSeller',
        label: 'Réf.',
        format: value => value,
    },
    {
        id: 'sendEmail',
        label: '',
        // eslint-disable-next-line react/display-name
        formatWithObj: obj =>
            <ServicesEmailMenu
                size="small"
                cardNameSeller={obj.baseObject['cardNameVendeur']}
                vehiclePlateNumber={obj.baseObject['immatriculation']}
                vehicleDocNum={obj.baseObject['dossierRefInt']}
                subStatus={obj.baseObject['sousStatut']}
                status={obj.baseObject['statut']}
            />,
    },
    {
        id: 'hasMemo',
        label: 'Memo',
        // eslint-disable-next-line react/display-name
        formatWithObj: obj => !obj.externalMemo
            ? <FontAwesomeIcon icon={faStickyNote} style={{fontSize: '1rem', color: red[500]}}/>
            : <FontAwesomeIcon icon={faStickyNote} style={{fontSize: '1rem', color: '#28a745'}}/>,
    },
    {
        id: 'nj',
        label: 'Nb. jour',
        formatWithObj: obj => {
            let now = moment(),
                createdDate = moment(obj.createdDate, 'DD/MM/YYYY');

            return now.diff(createdDate, 'days');
        },
    },
    {
        id: 'plateNumber',
        label: 'Immatriculation',
        format: value => value,
    },
    {
        id: 'type',
        label: 'Genre',
        format: value => value,
    },
    {
        id: 'brand',
        label: 'Marque',
        format: value => value,
    },
    {
        id: 'commercialType',
        label: 'Type comm.',
        format: value => value,
    },
    {
        id: 'ownerDebtor',
        label: 'Propriét./Déb.',
        format: value => value,
    },
    {
        id: 'deliveredAtMoment',
        label: 'Date livraison',
        format: value => value ? value.format('DD/MM/YYYY') : '',
    },
    {
        id: 'linkFolder',
    },
]

const VehiclesBlocked = ({
    vehiclesBlockedLoaded,
    vehiclesIsLoading,
    selectedUser,
    choicesUser,
    dispatch,
}) => {
    const vehiclesBlocked = useSelector(vehiclesBlockedModel);

    useEffect(() => {
        if (((choicesUser.length !== 0 && selectedUser) || (choicesUser.length === 0 && !selectedUser)) && !vehiclesBlockedLoaded) {
            fetchVehiclesBlocked(selectedUser);
            dispatch(setBreadcrumbs(null));
        }

        // TODO : Put a button which allow to reload the list.
    }, [vehiclesBlockedLoaded, selectedUser]);

    return (
        <>
            {!vehiclesBlockedLoaded || isEmpty(vehiclesBlocked)
                ? <DataTable
                    columns={DataTableColumns}
                    data={[]}
                    loading={vehiclesIsLoading}
                    searchInputPlaceHolder="Recherche par immat."
                />
                : Object.keys(vehiclesBlocked).map(key => 
                    <DataTable
                        key={key}
                        columns={DataTableColumns}
                        data={vehiclesBlocked[key]}
                        title={subStatusToTitle(key)}
                        searchableColumns={['plateNumber']}
                        searchInputPlaceHolder="Recherche par immat."
                    />
                )
            }
        </>
    );
}

VehiclesBlocked.propTypes = {
    vehiclesBlockedLoaded: PropTypes.bool,
    vehiclesIsLoading: PropTypes.bool,
    selectedUser: PropTypes.any,
    choicesUser: PropTypes.any,
    dispatch: PropTypes.func,
}

export default connect(state => ({
    vehiclesBlockedLoaded: state.vehiclesReducer.vehiclesBlockedLoaded,
    vehiclesIsLoading: state.vehiclesReducer.vehiclesBlockedIsLoading,
    selectedUser: state.userReducer.selectedUser,
    choicesUser: state.userReducer.choicesUser,
}))(VehiclesBlocked);